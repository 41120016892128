import React, { useEffect, useState, useRef } from "react";
import { LoadingOverlay, LoadingDocFallback } from "./LoadingDocument";
import {
  PlusIcon,
  MinusIcon,
  ArrowKeyLeftIcon,
  ArrowKeyRightIcon,
  ArrowKeyUpIcon,
  ArrowKeyDownIcon,
  RotatePageIcon,
  PrinterIcon,
  DownLoadArrowTrayIcon,
  ThreeBarsIcon,
} from "../assets/icon-file";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";
GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.8.69/pdf.worker.min.mjs";

const PDFViewer = (props) => {
  const { id, pdfUrl, downloadFile } = props;
  const [loading, setLoading] = useState(true);
  const [sideContainerLoading, setSideContainerLoading] = useState(true);
  const [pagesLoaded, setPagesLoaded] = useState(0);
  const [progressBarWidth, setProgressBarWidth] = useState(0);
  const [pdfToolsDisabled, setPdfToolsDisabled] = useState(true);
  const [pdfContainerWidth, setPdfContainerWidth] = useState(0); // To store pdf container + side nav width
  const [initialPdfPageWidth, setInitialPdfPageWidth] = useState(0);
  const [pageWidthPercentage, setPageWidthPercentage] = useState(1.0);
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pdfFull, setPdfFull] = useState(false);
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const [transform, setTransform] = useState(0);
  const [loadingFallback, setLoadingFallback] = useState(false);
  const viewerRef = useRef(null); // Ref for the viewer container
  const sideViewerRef = useRef(null); // Ref for the viewer container
  const percentageInputRef = useRef(null); // Ref for zoom percentage input
  const pageWidthOptions = [
    0.25, 0.33, 0.5, 0.67, 0.75, 0.8, 0.9, 1.0, 1.1, 1.25, 1.5, 1.75, 2.0, 2.5,
    3.0,
  ];
  const pdfName = decodeURIComponent(pdfUrl.split("/").pop());
  // removes invalid css selector characters
  const pdfNameFiltered = pdfName.replace(/\W/g, "");

  useEffect(() => {
    if (pdfContainerWidth && numPages) {
      setProgressBarWidth((pdfContainerWidth / numPages) * pagesLoaded);
    }
  }, [pagesLoaded]);

  useEffect(() => {
    // Update the container width on load
    const updateWidth = () => {
      if (viewerRef.current) {
        setPdfContainerWidth(viewerRef.current.offsetWidth);
      }
    };
    updateWidth();
    // window.addEventListener("resize", updateWidth);
    // return () => window.removeEventListener("resize", updateWidth);
  }, []);

  // For endering the pdf in the main container
  useEffect(() => {
    const renderPDF = async () => {
      if (!pdfContainerWidth) return;
      try {
        // Load the PDF document
        const loadingTask = getDocument(pdfUrl);
        const pdf = await loadingTask.promise;
        setNumPages(pdf._pdfInfo.numPages);
        for (let page = 1; page <= pdf._pdfInfo.numPages; page++) {
          const currentPage = await pdf.getPage(page);
          const scaledViewport = currentPage.getViewport({ scale: 1.33 });
          // Create and configure canvas
          let canvas = document.createElement("canvas");
          canvas.classList.add(`${pdfNameFiltered}-canvas`);
          canvas.width = scaledViewport.width;
          canvas.height = scaledViewport.height;
          const context = canvas.getContext("2d");

          // Append canvas to the viewer container using the ref
          let div = document.createElement("div");
          div.id = `${pdfNameFiltered}-${page}`;
          div.classList.add(`${pdfNameFiltered}-container`);
          div.style.position = "relative";
          div.style.margin = "0 auto 12px auto"; // Default margin
          div.appendChild(canvas);

          if (viewerRef.current) {
            viewerRef.current.appendChild(div);
          }
          // Render the page on the canvas
          await currentPage.render({
            canvasContext: context,
            viewport: scaledViewport,
          }).promise;
          setPagesLoaded((prev) => prev + 1);
          setInitialPdfPageWidth(scaledViewport.width);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error rendering PDF:", error);
        setLoadingFallback(true);
      }
      setPdfToolsDisabled(false);
    };
    renderPDF();
  }, [pdfContainerWidth]);

  // For endering the pdf in the side nav
  useEffect(() => {
    const renderPdfInSideNav = async () => {
      if (!pdfContainerWidth || window.innerWidth < 1024) return;
      try {
        // Load the PDF document
        const loadingTask = getDocument(pdfUrl);
        const pdf = await loadingTask.promise;
        for (let page = 1; page <= pdf._pdfInfo.numPages; page++) {
          const currentPage = await pdf.getPage(page);
          const sideContainerWidth = pdfContainerWidth / 4;
          const pageFitToPort =
            sideContainerWidth / currentPage.getViewport({ scale: 1 }).width;
          const scaledViewport = currentPage.getViewport({
            scale: pageFitToPort - pageFitToPort * 0.4,
          });

          // Create and configure side canvas
          let sideCanvas = document.createElement("canvas");
          sideCanvas.classList.add(`${pdfNameFiltered}-canvas-side`);
          sideCanvas.width = scaledViewport.width;
          sideCanvas.height = scaledViewport.height;

          const context = sideCanvas.getContext("2d");

          // Append side canvas to the viewer container using the ref
          let sideDiv = document.createElement("div");
          sideDiv.id = `${pdfNameFiltered}-${page}-side`;
          sideDiv.classList.add(`${pdfNameFiltered}-container-side`);
          sideDiv.style.position = "relative";
          sideDiv.style.margin = "24px auto 0"; // Default margin
          sideDiv.appendChild(sideCanvas);
          let pageNumber = document.createElement("div");
          pageNumber.classList.add("page-number");
          pageNumber.innerHTML = page;
          // pageNumber.style.position = "absolute";
          pageNumber.style.marginTop = "12px";
          pageNumber.style.textAlign = "center";
          pageNumber.style.verticalAlign = "bottom";
          pageNumber.style.color = "white";
          pageNumber.style.lineHeight = "14px";
          pageNumber.style.fontSize = "14px";
          sideDiv.appendChild(pageNumber);
          const highlightElement = (event, pageNumber = page) => {
            const canvases = document.querySelectorAll(
              `.${pdfNameFiltered}-canvas-side`
            );
            canvases.forEach((canvas) => {
              canvas.style.border = "none";
            });
            // Add border to the clicked div
            event.target.style.border = "6px solid #93b3f3";
            scrollToPage(pageNumber);
          };
          sideCanvas.onclick = highlightElement;
          if (sideViewerRef.current) {
            sideViewerRef.current.appendChild(sideDiv);
          }
          // Render the page on the canvas
          await currentPage.render({
            canvasContext: context,
            viewport: scaledViewport,
          }).promise;
        }
        setSideContainerLoading(false);
      } catch (error) {
        console.error("Error rendering PDF:", error);
        setLoadingFallback(true);
      }
    };
    renderPdfInSideNav();
  }, [pdfContainerWidth]);

  const handleSideNav = () => {
    if (sideNavOpen) {
      handleZoomIn(pageWidthPercentage);
    } else {
      handleZoomOut(pageWidthPercentage);
    }
    setSideNavOpen(!sideNavOpen);
  };

  const scrollToPage = (pageNumber) => {
    const pdfPages = viewerRef.current.querySelectorAll(
      `.${pdfNameFiltered}-container`
    );
    if (pdfPages[pageNumber - 1]) {
      const { top } = pdfPages[pageNumber - 1].getBoundingClientRect();
      const containerTop = viewerRef.current.getBoundingClientRect().top;
      const offset = top - containerTop + viewerRef.current.scrollTop;
      viewerRef.current.scrollTo({ top: offset });
      setCurrentPage(pageNumber);
    }
  };
  const stopScrollPropagation = (e) => e.stopPropagation();

  const handlePageSelect = (e) => {
    const pageNum = e.target.value;
    if (!/^[0-9]+$/.test(pageNum) || (pageNum.length > 0 && pageNum < 1)) {
      return;
    }
    scrollToPage(pageNum);
    setCurrentPage(pageNum);
  };

  const handlePercentageInput = (e, percentage) => {
    const validChars = /^-?\d*\.?\d+$/;
    if (e.key === "Enter" && validChars.test(percentage)) {
      let newPercentage =
        percentage > 300 ? 300 : percentage < 25 ? 25 : percentage;
      newPercentage = Math.floor(newPercentage);
      percentageInputRef.current.value = `${newPercentage}%`;
      if (newPercentage === 43) {
        // If handled by handleZoomOut, there is a condition there that will handle the pdfFull state
        handleZoomIn(newPercentage / 100);
      } else {
        handleZoomOut(newPercentage / 100);
      }
    }
  };

  const handleZoomOut = (minimize = null) => {
    // Prevent running the function after reaching the smallest size
    if (pageWidthOptions.indexOf(pageWidthPercentage) === 0 && !minimize) {
      return;
    }
    // Determines if double arrow icon clicked or minus icon clicked
    const percentageToMinimize = !minimize
      ? findNextLowestPercentage(pageWidthPercentage)
      : minimize;
    const pages = document.querySelectorAll(`.${pdfNameFiltered}-container`);
    pages.forEach((page) => {
      const canvas = page.querySelector(`.${pdfNameFiltered}-canvas`);
      canvas.style.width = `${(
        initialPdfPageWidth * percentageToMinimize
      ).toString()}px`;
      if (transform % 180 !== 0) {
        adjustMargins(canvas, page, 12);
      }
    });
    if (minimize === 0.43) {
      setPdfFull(!pdfFull);
    }
    percentageInputRef.current.value = `${(percentageToMinimize * 100).toFixed(
      0
    )}%`;
    setPageWidthPercentage(percentageToMinimize);
  };

  const findNextLowestPercentage = (currentPercentage) => {
    for (let num of pageWidthOptions.reverse()) {
      if (num < currentPercentage) {
        return num;
      }
    }
  };

  const handleZoomIn = (increase = null) => {
    // The index will start from 0 when the array reaches the beyond its length
    if (
      pageWidthOptions.indexOf(pageWidthPercentage) ===
      pageWidthOptions.length - 1
    ) {
      return;
    }
    // Determines if double arrow icon clicked or plus icon clicked
    const percentageToIncrease = !increase
      ? findNextHighestPercentage(pageWidthPercentage)
      : increase === pdfContainerWidth
      ? increase / initialPdfPageWidth
      : increase;
    const pages = document.querySelectorAll(`.${pdfNameFiltered}-container`);
    pages.forEach((page) => {
      const canvas = page.querySelector(`.${pdfNameFiltered}-canvas`);
      canvas.style.width = `${(!increase
        ? initialPdfPageWidth * percentageToIncrease
        : increase === pdfContainerWidth
        ? pdfContainerWidth
        : initialPdfPageWidth * percentageToIncrease
      ).toString()}px`;
      if (transform % 180 !== 0) {
        adjustMargins(canvas, page, 12);
      }
    });
    if (increase === pdfContainerWidth) {
      setPdfFull(!pdfFull);
    }
    percentageInputRef.current.value = `${(percentageToIncrease * 100).toFixed(
      0
    )}%`;
    setPageWidthPercentage(percentageToIncrease);
  };

  const findNextHighestPercentage = (currentPercentage) => {
    for (let num of pageWidthOptions) {
      if (num > currentPercentage) {
        return num;
      }
    }
  };

  const rotatePdf = () => {
    const pages = document.querySelectorAll(`.${pdfNameFiltered}-container`);
    pages.forEach((page) => {
      const canvas = page.querySelector(`.${pdfNameFiltered}-canvas`);
      const rotateProps = !canvas.style.transform
        ? { degrees: "270", marginDif: 12 }
        : canvas.style.transform === `rotate(${90}deg)`
        ? { degrees: "360", marginDif: "0 0 12px 0" }
        : canvas.style.transform === `rotate(${180}deg)`
        ? { degrees: "90", marginDif: 12 }
        : canvas.style.transform === `rotate(${270}deg)`
        ? { degrees: "180", marginDif: "0 0 12px 0" }
        : { degrees: "270", marginDif: 12 };

      const { degrees, marginDif } = rotateProps;
      canvas.style.transform = `rotate(${degrees}deg)`;
      canvas.style.transformOrigin = "center";
      setTransform(degrees);
      // Adjust margin for rotation
      if (degrees % 180 === 0) {
        page.style.margin = marginDif;
      } else {
        // If rotated 90 or 270 degrees, adjust width/height
        adjustMargins(canvas, page, marginDif);
      }
    });

    const sidePages = document.querySelectorAll(
      `.${pdfNameFiltered}-container-side`
    );
    sidePages.forEach((sidePage) => {
      const canvas = sidePage.querySelector(`.${pdfNameFiltered}-canvas-side`);
      const rotateProps = !canvas.style.transform
        ? { degrees: "270", marginDif: 24 }
        : canvas.style.transform === `rotate(${90}deg)`
        ? { degrees: "360", marginDif: "24px auto 0" }
        : canvas.style.transform === `rotate(${180}deg)`
        ? { degrees: "90", marginDif: 24 }
        : canvas.style.transform === `rotate(${270}deg)`
        ? { degrees: "180", marginDif: "24px auto 0" }
        : { degrees: "270", marginDif: 24 };

      const { degrees, marginDif } = rotateProps;
      canvas.style.transform = `rotate(${degrees}deg)`;
      canvas.style.transformOrigin = "center";
      setTransform(degrees);
      // Adjust margin for rotation
      const pageNumber = sidePage.querySelector(".page-number");
      if (degrees % 180 === 0) {
        sidePage.style.margin = marginDif;
        pageNumber.style.marginTop = "12px";
      } else {
        // If rotated 90 or 270 degrees, adjust width/height
        const adjustedMargin = (canvas.width - canvas.height) / 2;
        sidePage.style.marginTop = `${adjustedMargin + marginDif}px`;
        sidePage.style.marginBottom = `${adjustedMargin + marginDif}px`;
        pageNumber.style.marginTop = "-12px";
      }
    });
  };

  const handleDownloadPdf = async () => {
    try {
      // Fetch the PDF document
      const loadingTask = getDocument(pdfUrl);
      const pdf = await loadingTask.promise;
      // Fetch the raw data of the PDF as an array buffer
      const pdfData = await pdf.getData();
      const blob = new Blob([pdfData], { type: "application/pdf" });
      // Create an object URL for the blob
      const pdfUrlForDownload = URL.createObjectURL(blob);
      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = pdfUrlForDownload;
      link.download = pdfName;
      link.target = "_blank";
      link.click();
      // Clean up the object URL after use
      URL.revokeObjectURL(pdfUrlForDownload);
    } catch (error) {
      console.error("Error downloading the PDF:", error);
    }
  };

  const handlePrintPdf = async () => {
    try {
      // Fetch the PDF document
      const loadingTask = getDocument(pdfUrl);
      const pdf = await loadingTask.promise;
      // Fetch the raw data of the PDF as an array buffer
      const pdfData = await pdf.getData();
      const blob = new Blob([pdfData], { type: "application/pdf" });

      // Create an object URL for the blob
      const pdfUrlForPrint = URL.createObjectURL(blob);
      // Open the PDF in a new tab
      const printWindow = window.open(pdfUrlForPrint);

      if (!printWindow) {
        alert(
          "The PDF could not be opened. Please allow pop-ups and try again."
        );
        return;
      }

      // Wait for the new window to load and then trigger print
      printWindow.onload = () => {
        setTimeout(() => {
          printWindow.print();

          // Clean up the object URL after use
          URL.revokeObjectURL(pdfUrlForPrint);
        }, 1000);
      };
    } catch (error) {
      console.error("Error printing the PDF:", error);
    }
  };

  const adjustMargins = (canvas, page, marginDif) => {
    // If rotated 90 or 270 degrees, adjust width/height
    const adjustedMargin = (canvas.clientWidth - canvas.clientHeight) / 2;
    page.style.marginTop = `${adjustedMargin}px`;
    page.style.marginBottom = `${adjustedMargin + marginDif}px`;
  };

  if (loadingFallback) {
    return (
      <LoadingDocFallback id={id} url={pdfUrl} downloadFile={downloadFile} />
    );
  }
  return (
    <div className="relative mb-3 h-[500px]">
      {loading && (
        <LoadingOverlay
          height="[500px]"
          text="Loading PDF..."
          textSize="text-md"
          classes="absolute"
        />
      )}
      {/* Toolbar */}
      <div
        className={`${
          pdfToolsDisabled ? "opacity-75" : ""
        } grid grid-cols-2 grid-cols-[minmax(calc(100%-84px),calc(100%-84px))_minmax(84px,84px)] lg:grid-cols-3 justify-center ${
          pagesLoaded === numPages ? "h-[58px]" : "h-[54px] pt-[4px]"
        } bg-[#333639] text-white items-center`}
      >
        <div className="hidden lg:flex gap-3 ml-3 ml-2 justify-center items-center">
          <button
            disabled={sideContainerLoading ?? false}
            className={`flex justify-center items-center cursor-pointer w-8 h-8 border-0 rounded-full ${
              sideContainerLoading ? "" : "hover:bg-[#434649]"
            }`}
            onClick={() => handleSideNav()}
          >
            <ThreeBarsIcon classes="text-white w-5 h-5" />
          </button>
          <div className="text-sm truncate">{pdfName}</div>
        </div>
        <div className="flex justify-center ml-[84px] lg:ml-0 items-center">
          <div className="pr-4 max-[639px]:hidden">
            <input
              disabled={pdfToolsDisabled ?? false}
              value={currentPage}
              onChange={(e) => handlePageSelect(e)}
              className="text-white"
              style={{
                all: "unset",
                width: `${numPages?.toString().split("").length * 14}px`,
                textAlign: "center",
                backgroundColor: "#1a1b1c",
                fontSize: "14px",
              }}
            />
            <span className="text-white text-sm"> / {numPages}</span>
          </div>
          <span className="max-[639px]:hidden text-[#717375]">|</span>
          <div className="px-1 flex items-center">
            <button
              disabled={pdfToolsDisabled ?? false}
              className={`cursor-pointer w-8 h-8 border-0 rounded-full ${
                pdfToolsDisabled ? "" : "hover:bg-[#434649]"
              }`}
              onClick={() => handleZoomOut()}
            >
              <MinusIcon classes="w-5 h-5 m-auto text-white font-extrabold" />
            </button>
            <span>
              <input
                ref={percentageInputRef}
                disabled={pdfToolsDisabled ?? false}
                type="text"
                defaultValue={`${(pageWidthPercentage * 100).toFixed(0)}%`}
                onChange={(e) =>
                  (percentageInputRef.current.value = e.target.value)
                }
                onKeyDown={(e) =>
                  handlePercentageInput(e, percentageInputRef.current.value)
                }
                className="w-14 text-sm text-center px-2 mx-1 bg-[#1a1b1c] text-white"
              />
            </span>
            <button
              className={`cursor-pointer w-8 h-8 border-0 rounded-full ${
                pdfToolsDisabled ? "" : "hover:bg-[#434649]"
              }`}
              disabled={pdfToolsDisabled ?? false}
              onClick={() => handleZoomIn()}
            >
              <PlusIcon classes="w-4 h-4 m-auto text-white font-extrabold" />
            </button>
          </div>
          <span className="max-[639px]:hidden text-[#717375]">|</span>
          <div className="max-[639px]:hidden">
            {!pdfFull && (
              <div className="pl-4">
                <button
                  disabled={pdfToolsDisabled ?? false}
                  className={`cursor-pointer w-8 h-8 border-0 rounded-full ${
                    pdfToolsDisabled ? "" : "hover:bg-[#434649]"
                  } flex justify-center items-center`}
                  onClick={() => handleZoomIn(pdfContainerWidth)}
                >
                  <div className="flex w-5 h-4 items-center justify-center border-white border-2">
                    <ArrowKeyLeftIcon classes="w-[5px] h-[5px] text-white mr-1 " />
                    <ArrowKeyRightIcon classes="w-[5px] h-[5px] text-white ml-1 " />
                  </div>
                </button>
              </div>
            )}
            {pdfFull && (
              <div className="pl-4 flex">
                <button
                  disabled={pdfToolsDisabled ?? false}
                  className={`cursor-pointer w-8 h-8 border-0 rounded-full ${
                    pdfToolsDisabled ? "" : "hover:bg-[#434649]"
                  } flex justify-center items-center`}
                  onClick={() => handleZoomOut(0.43)}
                >
                  <div className="inline-flex flex-col w-5 h-4 items-center justify-center border-white border-2">
                    <ArrowKeyUpIcon classes="w-[5px] h-[5px] text-white" />
                    <ArrowKeyDownIcon classes="w-[5px] h-[5px] text-white" />
                  </div>
                </button>
              </div>
            )}
          </div>
          <div className="md:pl-2">
            <button
              disabled={pdfToolsDisabled ?? false}
              onClick={() => rotatePdf()}
              className={`cursor-pointer w-8 h-8 border-0 rounded-full ${
                pdfToolsDisabled ? "" : "hover:bg-[#434649]"
              } flex justify-center items-center`}
            >
              <RotatePageIcon classes="w-5 h-5 decoration-white" />
            </button>
          </div>
        </div>
        <div className="mr-4 flex max-[1023px]:gap-1 sm:gap-3 justify-end">
          <button
            disabled={pdfToolsDisabled ?? false}
            className={`cursor-pointer w-8 h-8 border-0 rounded-full ${
              pdfToolsDisabled ? "" : "hover:bg-[#434649]"
            } flex justify-center items-center`}
            onClick={() => handleDownloadPdf()}
          >
            <DownLoadArrowTrayIcon classes="text-white w-5 h-5" />
          </button>
          <button
            disabled={pdfToolsDisabled ?? false}
            className={`cursor-pointer w-8 h-8 border-0 rounded-full ${
              pdfToolsDisabled ? "" : "hover:bg-[#434649]"
            } flex justify-center items-center`}
            onClick={() => handlePrintPdf()}
          >
            <PrinterIcon classes="text-white w-5 h-5" />
          </button>
        </div>
      </div>
      {pagesLoaded !== numPages && (
        <div className="w-full bg-gray-200 h-1">
          <div
            className="h-1 border-t border-b border-transparent bg-gradient-to-r from-[#333639] to-[#535659] transition-all ease-in-out duration-300"
            style={{ width: `${progressBarWidth}px` }}
          ></div>
        </div>
      )}
      <div className="flex w-full border-b-[1px] border-x-[1px] sm:border-b-[2px] sm:border-l-[2px] sm:border-r-0 border-[#333639]">
        <div
          id="pdf-sidebar"
          ref={sideViewerRef}
          onWheel={stopScrollPropagation}
          onTouchMove={stopScrollPropagation}
          className={`${
            sideNavOpen
              ? "transition-all ease-in-out duration-300 w-[25%] flex flex-col items-center h-[440px] overflow-y-scroll overflow-x-hidden bg-[#333639]"
              : "hidden"
          } `}
        ></div>
        <div
          id="pdf"
          ref={viewerRef}
          onWheel={stopScrollPropagation}
          onTouchMove={stopScrollPropagation}
          className={`${
            sideNavOpen
              ? "w-[75%] transition-all ease-in-out duration-300"
              : "w-full"
          }  px-1 flex flex-col items-center h-[440px] overflow-auto bg-[#535659]`}
        ></div>
      </div>
    </div>
  );
};

export default PDFViewer;
