import React, { Fragment, useState } from "react";
import CustomAudioPlayer from "../../ReuseableComponents/CustomPlayer";
import CustomVideoPlayer from "../../ReuseableComponents/CustomVideoPlayer";
import {
  CodeBracketIcon,
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Tab, Dialog, Transition } from "@headlessui/react";

function EmbedClass({ classData, handleMediaPlayback }) {
  const [open, setOpen] = useState(false);
  const [mediaData, setMediaData] = useState([]);
  const [startHereTime, setStartHereTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [checked, setChecked] = useState(false);
  const [copyDisabled, setCopyDisabled] = useState(false);
  const [copyStatus, setCopyStatus] = useState({
    noCopy: true,
    copySuccess: false,
    copyFail: false,
  });

  function openModal() {
    handleModalState();
    handleMediaData();
  }

  function handleModalState() {
    setOpen(true);
    setStartHereTime(0);
    setCurrentTime(0);
    setChecked(false);
    setCopyDisabled(false);
    setCopyStatus({ noCopy: true, copySuccess: false, copyFail: false });
  }

  function handleMediaData() {
    const mediaData = getMediaData();
    const mediaDataWithEmbedCode = addEmbedCodeToMediaData({ mediaData });
    setMediaData(mediaDataWithEmbedCode);
  }

  const getMediaData = () => {
    let mediaData = [];
    // Iterate over classData to get media type and url
    for (let i = 0; i < classData.blocks.length; i++) {
      if (classData.blocks[i].type === "Custom HTML") {
        // Function to get type and url from the html string
        mediaData = extractMediaData({
          htmlString: classData.blocks[i].html,
          blockId: classData.blocks[i].id,
        });
        break;
      }
      if (
        classData.blocks[i].type === "Audio" ||
        classData.blocks[i].type === "Video"
      ) {
        mediaData.push({
          id: classData.blocks[i].id,
          type: classData.blocks[i].type,
          url: classData.blocks[i].url,
        });
      }
    }
    return mediaData;
  };

  const extractMediaData = ({ htmlString, blockId }) => {
    // Parse the HTML string into a DOM object
    const extractedData = [];
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const videoTag = doc.querySelector("video");
    const videoUrl = videoTag?.getAttribute("src");
    if (videoTag) {
      extractedData.push({ id: blockId, url: videoUrl, type: "Video" });
    }
    const audioTag = doc.querySelector("audio");
    const audioUrl = audioTag?.getAttribute("src");
    if (audioTag) {
      extractedData.push({ id: blockId, url: audioUrl, type: "Audio" });
    }
    return extractedData;
  };

  const addEmbedCodeToMediaData = ({ mediaData, startHere = "" }) => {
    const mediaDataWithEmbedCode = mediaData.map((media) => ({
      ...media,
      embedCode: getEmbedCode({
        url: media.url,
        type: media.type,
        start: startHere,
      }),
    }));
    if (mediaDataWithEmbedCode[0].type === "Audio") {
      mediaDataWithEmbedCode.reverse();
    }
    return mediaDataWithEmbedCode;
  };

  const getEmbedCode = ({ url, type, start }) => {
    let embedCode = generateEmbedCode({ url, type, start });
    if (!embedCode) {
      embedCode = "Embed code failed to generate";
      setCopyDisabled(true);
    }
    return embedCode;
  };

  const generateEmbedCode = ({ url, type, start }) => {
    let embedCode;
    if (type === "Video") {
      embedCode = `<video id="video" src="${url}" controls width="560" height="315"></video>`;
    }
    if (type === "Audio") {
      embedCode = `<audio id="audio" controls src="${url}" type="audio/mpeg"></audio>`;
    }
    if (start) {
      embedCode += `
        <script>
          document.getElementById("${
            type === "Video" ? "video" : "audio"
          }").addEventListener(
            "loadedmetadata",
            function () {
              this.currentTime = ${Math.floor(start)};
            },
            false
          );
        </script>
      `;
    }
    return embedCode;
  };

  const handleCopy = async (embedCode) => {
    try {
      await navigator.clipboard.writeText(embedCode);
      setCopyStatus((prev) => ({ ...prev, copySuccess: true }));
    } catch (error) {
      console.log(error);
      setCopyStatus((prev) => ({ ...prev, copyFail: true }));
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  function handleStartHereTime(currentTime) {
    setCurrentTime(currentTime);
    setStartHereTime(checked ? currentTime : 0);
  }

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    return `${
      hours
        ? `${String(hours)}:${String(minutes).padStart(2, "0")}`
        : `${String(minutes)}`
    }:${String(seconds).padStart(2, "0")}`;
  };

  function handleStartTime() {
    if (!checked) {
      setStartHereTime(currentTime);
    } else {
      setStartHereTime(0);
    }
    const mediaDataWithEmbedCode = addEmbedCodeToMediaData({
      mediaData: mediaData,
      startHere: !checked ? currentTime : 0,
    });
    setMediaData(mediaDataWithEmbedCode);
    setChecked(!checked);
  }

  return (
    <div className="flex justify-center items-center w-8 h-8 bg-gray-300 border-[1px] border-gray-400 rounded-full">
      <button onClick={() => openModal()}>
        <CodeBracketIcon className="w-6 h-6 text-gray-600" />
      </button>

      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all">
                  <Tab.Group onChange={() => openModal()}>
                    <div className="bg-white">
                      <Tab.List className="flex rounded-t-2xl bg-blue-900/20 p-1">
                        {mediaData.length > 0 &&
                          mediaData.map((source) => (
                            <Tab
                              key={source.type}
                              className={({ selected }) =>
                                classNames(
                                  `w-full ${
                                    mediaData.length > 1 &&
                                    source.type === "Video"
                                      ? "rounded-tl-xl"
                                      : mediaData.length > 1 &&
                                        source.type === "Audio"
                                      ? "rounded-tr-xl"
                                      : "rounded-t-xl"
                                  } h-6 lg:h-10 py-1 lg:py-2.5 text-xs lg:text-sm font-medium`,
                                  selected
                                    ? "bg-white text-blue-700 shadow"
                                    : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
                                )
                              }
                            >
                              Embed {source.type}
                            </Tab>
                          ))}
                      </Tab.List>
                    </div>
                    <Tab.Panels className="">
                      {mediaData.length > 0 &&
                        mediaData.map((source) => (
                          <Tab.Panel
                            key={source.type}
                            className={classNames(
                              "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
                            )}
                          >
                            <div className="lg:flex">
                              {source.type === "Video" ? (
                                <CustomVideoPlayer
                                  handleStartHereTime={handleStartHereTime}
                                  checked={checked}
                                  addEmbedCodeToMediaData={
                                    addEmbedCodeToMediaData
                                  }
                                  mediaData={mediaData}
                                  setMediaData={setMediaData}
                                  modal={true}
                                  videoUrl={source.url}
                                  onPlay={() =>
                                    handleMediaPlayback(source.id, "Video")
                                  }
                                />
                              ) : (
                                <CustomAudioPlayer
                                  handleStartHereTime={handleStartHereTime}
                                  checked={checked}
                                  addEmbedCodeToMediaData={
                                    addEmbedCodeToMediaData
                                  }
                                  mediaData={mediaData}
                                  setMediaData={setMediaData}
                                  modal={true}
                                  audioUrl={source.url}
                                  onPlay={() =>
                                    handleMediaPlayback(source.id, "Audio")
                                  }
                                />
                              )}
                              <div className="w-[328px] lg:w-[384px] px-3 py-2 lg:p-4 bg-white">
                                <Dialog.Title
                                  as="h3"
                                  className="text-lg font-medium leading-6 text-gray-900"
                                >
                                  Embed Code
                                </Dialog.Title>
                                <div className="">
                                  <p className="text-sm text-gray-500 break-words h-[140px] lg:h-[181px] mb-2 lg:mb-4 overflow-auto">
                                    {source.embedCode}
                                  </p>
                                </div>
                                <div>
                                  <div className="flex items-center">
                                    <input
                                      onChange={() => handleStartTime()}
                                      type="checkbox"
                                      value={checked}
                                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                      Start here: {formatTime(startHereTime)}
                                    </label>
                                  </div>
                                </div>
                                <div className="mt-2 lg:mt-4 flex items-center lg:block">
                                  <button
                                    disabled={copyDisabled}
                                    type="button"
                                    className={`mr-2 py-1 px-3 inline-flex items-center justify-center rounded-md border border-transparent text-sm leading-4 lg:leading-5 font-medium bg-blue-100 ${
                                      copyDisabled
                                        ? " bg-blue-50 text-blue-200"
                                        : "text-blue-900 hover:bg-blue-200"
                                    } focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2`}
                                    onClick={() => handleCopy(source.embedCode)}
                                  >
                                    <span>Copy</span>
                                  </button>
                                  <div className="lg:mt-2 h-5">
                                    {" "}
                                    {copyStatus.copySuccess ? (
                                      <span className="w-fit px-1 flex text-sm bg-green-100 text-green-700">
                                        <CheckIcon className="mr-1" />
                                        Copied!
                                      </span>
                                    ) : copyStatus.copyFail ? (
                                      <span className="w-fit px-1 flex text-sm text-red-600 ">
                                        <XMarkIcon className="w-5 h-5 mr-1" />
                                        Copy Failed!
                                      </span>
                                    ) : (
                                      <span></span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Panel>
                        ))}
                    </Tab.Panels>
                  </Tab.Group>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default EmbedClass;
