export function LoadingOverlay(props) {
  const { height, text, textSize, textClasses, classes = "" } = props;
  return (
    <div
      className={`${classes} z-10 h-${height} w-full flex items-center justify-center border border-gray-200 bg-gray-50 dark:bg-gray-800 dark:border-gray-700`}
    >
      <div
        className={`${textClasses} px-4 py-2 py-2 ${textSize} font-medium leading-none text-center text-blue-800 bg-blue-200 rounded-full animate-pulse dark:bg-blue-900 dark:text-blue-200`}
      >
        {text}
      </div>
    </div>
  );
}

export function LoadingDocFallback(props) {
  const { id, url, downloadFile } = props;
  return (
    <div className="flex flex-wrap items-center justify-center text-center text-lg px-6 ">
      <p id={id}>
        Unable to display a
        {url.split(".").pop() === "pdf"
          ? " PDF "
          : url.split(".").pop() === "docx"
          ? " Word "
          : ""}
        document.
      </p>
      <button
        onClick={() => downloadFile(url)}
        className="p-2 cursor-pointer text-blue-500 hover:text-blue-800"
      >
        Download instead.
      </button>
    </div>
  );
}
