import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateAdminUser } from '../../store/slices/AdminUserSlice';
import BlockTextField from './BlockTextField';
import BlockSelectFile from './BlockSelectFile';
import NewBlockModal from '../EditorNewBlock/NewBlockModal';
import MediaGalleryModal from '../MediaGallary/MediaGalleryModal';
import { DragAndDrop, DraggableFileBlock, DraggableTextBlock, Drop } from "../../assets/drag-and-drop";
import { reorder } from "../../assets/drag-and-drop/helpers.js";
export default function Blocks(props) {
  const { 
      formData, 
      blocks,
      setBlocks,
      selectedBlockTitle,
      setSelectedBlockTitle,
      isClass = false
  } = props

  const [isOpen, setIsOpen] = useState(false);
  const [blockIndex, setBlockIndex] = useState();
  const [isOpenMedia ,setIsOpenMedia]= useState(false);
  const [mediatype, setMediatype] = useState('all');
  const [id, setId] = useState(NaN)
  const dispatch = useDispatch();

  const removeBlock = (indexToRemove, event) => {
    event.stopPropagation();
    const newArray = blocks.filter((item, index) => index !== indexToRemove);
    setBlocks(newArray);
    const newBlockNames = newArray.map(block => block.block);
    if (!newBlockNames.includes(selectedBlockTitle?.split(" ")[0])) {
        setSelectedBlockTitle("");
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    dispatch(updateAdminUser({
        editorFormData: {
            ...formData,
            [name]: value
        }
    }));
  }

  const handleBlockChange = (e, index, chunkedFile) => {
    const value = chunkedFile || (e.target.type === 'file' ? e.target.files[0] : e.target.value);
    setBlocks(prevData => {
        // Create a new array by mapping over the previous state
        return prevData.map((item, i) => {
            if (i === index) {
                // Update the specific object in the array
                return { ...item, value: value };
            }
            return item;
        });
    });
  };

  const AddNewBlock = (type) => {
    // Create a new array by spreading the existing state. id needed for drag-n-drop. isFileUploaded for styling blocks.
    const updatedBlocks = [...blocks, { id: id, block: type, value: '', isFileUploaded: false }];
    // Update the state with the new array
    setBlocks(updatedBlocks);
    setId((prevId) => prevId + 1)
  }

  const openMedia = (index, mediatype) => {
    setMediatype(mediatype)
    setIsOpenMedia(true)
    setBlockIndex(index)
  }

  const selectedMedia = (item) => {
    const newData = [...blocks];
    newData[blockIndex] = { ...newData[blockIndex], value: item.url };
    setBlocks(newData);
    setIsOpenMedia(false)
  }

  const handleDragEnd = (result) => {
    const { type, source, destination } = result;
    if (!destination) return;
    // Reordering blocks
    if (type === "droppable-block") {
      const updatedBlocks = reorder(
        blocks,
        source.index,
        destination.index
      );
      setBlocks(updatedBlocks);
    }
  };

  const handleReorderBlocks = (index, down = null) => {
    // Create new array so UI rerenders on setBlocks
    const newOrder = blocks.map(block => block)
    const temporaryVar = newOrder[index];
    newOrder[index] = newOrder[down ? index + 1 : index - 1];
    newOrder[down ? index + 1 : index - 1] = temporaryVar;
    setBlocks(newOrder)
  };

  return (
    <>
        <main className='flex-grow space-y-6 max-w-[65%]'>
            <div className='bg-whiteGrey rounded-lg h-[85px] p-4'>
            <label htmlFor="title" className="block text-base font-bold leading-5 text-primaryDark">
                Add {isClass ? 'class' : 'page'} title
            </label>
            <div className="">
                <input
                    id="title"
                    name="title"
                    type="text"
                    autoComplete="off"
                    required
                    value={formData.title}
                    placeholder='write here...'
                    onChange={handleChange}
                    className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                />
            </div>
            </div>
            <DragAndDrop onDragEnd={handleDragEnd}>
                <Drop id="droppable" type="droppable-block">
                {
                    blocks?.map((item, index) => {
                        const { id, block, value, isFileUploaded } = item;
                        return block === 'Paragraph' || block === 'Custom HTML' ? (
                            <DraggableTextBlock
                                key={id}
                                id={id.toString()}
                                index={index}
                            >
                                <div className="w-full" onClick={() => setSelectedBlockTitle(`${block} Options`)}>
                                    <BlockTextField
                                        block={block}
                                        index={index}
                                        handleBlockChange={handleBlockChange}
                                        removeBlock={removeBlock}
                                        data={item.value}
                                        blocks={blocks}
                                        handleReorderBlocks={handleReorderBlocks}
                                    />
                                </div>
                            </DraggableTextBlock>
                        ) : block ? (
                            <DraggableFileBlock
                                key={id}
                                id={id.toString()}
                                index={index}
                                isFileUploaded={isFileUploaded}
                            >
                                <div className="w-full" onClick={() => setSelectedBlockTitle(`${block} Options`)}>
                                    <BlockSelectFile
                                        fileType={block}
                                        inputType={
                                            block === "Images" ? 'image/*' : 
                                            block === 'Documents' ? '.doc,.docx,.pdf,.txt,.csv' : 
                                            `${block.toLowerCase()}/*`
                                        }
                                        index={index}
                                        removeBlock={removeBlock}
                                        openMedia={openMedia}
                                        data={value}
                                        blocks={blocks}
                                        setBlocks={setBlocks}
                                        selectedBlockTitle={selectedBlockTitle}
                                        handleReorderBlocks={handleReorderBlocks}
                                    />
                                </div>
                            </DraggableFileBlock>
                        ) : ""
                    })
                }
                </Drop>
            </DragAndDrop>
            <div className='flex justify-center mt-4'>
                <button
                    type="button"
                    onClick={() => setIsOpen(true)}
                    className="flex gap-x-2 px-5 h-11 items-center justify-center rounded-3xl border-[1px] border-primaryBlue hover:bg-opacity-[0.9] text-sm md:text-base font-bold leading-7 text-primaryBlue shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                >
                    Add New Block
                    <span aria-hidden="true" className='text-[13px]'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <g clipPath="url(#clip0_1363_6795)">
                                <path d="M11.4 5.4H6.60004V0.599963C6.60004 0.268835 6.3312 0 5.99996 0C5.66884 0 5.4 0.268835 5.4 0.599963V5.4H0.599963C0.268835 5.4 0 5.66884 0 5.99996C0 6.3312 0.268835 6.60004 0.599963 6.60004H5.4V11.4C5.4 11.7312 5.66884 12 5.99996 12C6.3312 12 6.60004 11.7312 6.60004 11.4V6.60004H11.4C11.7312 6.60004 12 6.3312 12 5.99996C12 5.66884 11.7312 5.4 11.4 5.4Z" fill="#186CCE" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1363_6795">
                                    <rect width="12" height="12" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </span>
                </button>
            </div>
        </main>
        <NewBlockModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            AddNewBlock={AddNewBlock}
        />
        <MediaGalleryModal
            mediatype={mediatype}
            setIsOpenMedia={setIsOpenMedia}
            isOpenMedia={isOpenMedia}
            selectedMedia={selectedMedia}
        />
    </>
  )
}