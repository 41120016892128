import React, { useState, useRef, useEffect } from "react";
import { setCookie, getCookie } from "../ReusableFunctions/cookieHandlers";
import {
  RewindTen,
  ForwordTen,
  VolumeIcon,
  VolumeMuteIcon,
  DownloadIcon,
  ExpandIcon,
} from "../assets/icon-file";
import {
  PlayButtonComputer,
  PlayButtonMobile,
  PlayInputComputer,
  PlayInputMobile,
} from "./CustomAudioVideoControls";

const CustomVideoPlayer = ({
  videoUrl,
  onPlay,
  modal = false,
  addEmbedCodeToMediaData,
  mediaData,
  setMediaData,
  checked = false,
  handleStartHereTime,
}) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [speed, setSpeed] = useState(1.0);
  const [volume, setVolume] = useState(1.0);
  const [isMuted, setIsMuted] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [playInline, setPlayInline] = useState(true);
  const [showExpand, setShowExpand] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    const savedTime = !modal ? getCookie(videoUrl) : "";
    if (savedTime) {
      setCurrentTime(savedTime);
      videoRef.current.currentTime = savedTime;
    }
  }, [videoUrl]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(document.fullscreenElement !== null);
    };
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () =>
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
  }, []);

  useEffect(() => {
    const videoElement = videoRef.current;
    const handleLoadedMetadata = () => {
      setDuration(videoElement.duration);
    };

    // const handleTimeUpdate = () => {
    //   setCurrentTime(videoElement.currentTime);
    // };
    const handleTimeUpdate = () => {
      if (!dragging) {
        setCurrentTime(videoElement.currentTime);
        if (modal) {
          handleStartHereTime(videoElement.currentTime);
        }
      }
    };
    const handleEnded = () => {
      // Video completed, pause and reset to start
      videoElement.pause();
    };

    videoElement.addEventListener("loadedmetadata", handleLoadedMetadata);
    videoElement.addEventListener("timeupdate", handleTimeUpdate);
    videoElement.addEventListener("ended", handleEnded);

    return () => {
      videoElement.removeEventListener("loadedmetadata", handleLoadedMetadata);
      videoElement.removeEventListener("timeupdate", handleTimeUpdate);
      videoElement.removeEventListener("ended", handleEnded);
    };
  }, [dragging, checked]);

  const playPauseToggle = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      if (onPlay) {
        onPlay(); // Call the onPlay function if provided
      }
    } else {
      videoRef.current.pause();
    }
  };

  const handlePlaybackSpeedChange = (newSpeed) => {
    setSpeed(newSpeed);
    videoRef.current.playbackRate = newSpeed;
  };

  const handleVolumeChange = (newVolume) => {
    setVolume(newVolume);
    videoRef.current.volume = newVolume;
    setIsMuted(false);
  };

  const toggleMute = () => {
    const newIsMuted = !isMuted;
    setIsMuted(newIsMuted);
    videoRef.current.muted = newIsMuted;
  };

  const handleTimeChange = (newTime) => {
    videoRef.current.currentTime = newTime;
    setCurrentTime(newTime);
    if (modal) {
      handleStartHereTime(newTime);
      if (checked) {
        const mediaDataWithEmbedCode = addEmbedCodeToMediaData({
          mediaData: mediaData,
          startHere: newTime,
        });
        setMediaData(mediaDataWithEmbedCode);
      }
    }
  };

  const handleDragStart = () => {
    setDragging(true);
  };

  const handleDragEnd = (e) => {
    setDragging(false);
    handleTimeChange(parseFloat(e.target.value));
  };
  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    return `${
      hours
        ? `${String(hours)}:${String(minutes).padStart(2, "0")}`
        : `${String(minutes)}`
    }:${String(seconds).padStart(2, "0")}`;
  };

  const downloadVideo = (videoUrl) => {
    // Create a link element
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = videoUrl;
    //a.download = 'downloaded_audio.mp3'; // You can specify the filename here

    // Append the link to the body
    document.body.appendChild(a);

    // Trigger the download by simulating a click on the link
    a.click();

    // Clean up by removing the link and revoking the blob URL
    window.URL.revokeObjectURL(videoUrl);
    document.body.removeChild(a);
    // // Use fetch to get the audio file
    // fetch(videoUrl)
    //   .then(response => {
    //     // Ensure the request was successful
    //     if (response.ok) return response.blob();
    //     throw new Error('Network response was not ok.');
    //   })
    //   .then(blob => {
    //     // Create a blob URL from the blob
    //     const url = window.URL.createObjectURL(blob);

    //     // Create a link element
    //     const a = document.createElement('a');
    //     a.style.display = 'none';
    //     a.href = url;
    //     a.download = 'downloaded_audio.mp3'; // You can specify the filename here

    //     // Append the link to the body
    //     document.body.appendChild(a);

    //     // Trigger the download by simulating a click on the link
    //     a.click();

    //     // Clean up by removing the link and revoking the blob URL
    //     window.URL.revokeObjectURL(url);
    //     document.body.removeChild(a);
    //   })
    //   .catch(error => {
    //     console.error('There was a problem with the fetch operation:', error);
    //   });
  };

  const handlePlayInline = () => {
    const videoElement = videoRef.current;
    if (!videoElement.fullscreenElement) {
      if (videoElement.requestFullscreen) {
        // Chrome, Firefox, Safari
        videoElement.requestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      } else if (videoElement.msRequestFullscreen) {
        // IE11
        videoElement.msRequestFullscreen();
      } else {
        // IPhone requires a work around when using customized controls
        setPlayInline(false);
      }
    }
  };

  useEffect(() => {
    if (!playInline) {
      async function expandScreen() {
        // The play command initiates the fullscreen mode. Capture currentTime to avoid changing where the video is at when the screen expands.
        const timeStamp = currentTime;
        if (!videoRef.current.paused) {
          videoRef.current.pause();
          videoRef.current.currentTime = timeStamp;
          videoRef.current.play();
          setPlayInline(true);
        } else {
          await videoRef.current.play();
          videoRef.current.pause();
          videoRef.current.currentTime = timeStamp;
          setPlayInline(true);
        }
      }
      expandScreen();
    }
  }, [playInline]);

  const handleExpand = () => {
    setShowExpand(true);
    setTimeout(() => {
      setShowExpand(false);
    }, 3000);
  };

  return (
    <div className={`${modal ? "w-[328px] lg:w-[384px]" : "relative"}`}>
      <video
        controls={isFullscreen ?? true}
        id="videoPlayer"
        playsInline={playInline}
        preload="metadata"
        onTimeUpdate={() => {
          if (!modal) {
            setCookie(videoUrl, currentTime);
          }
        }}
        onPlaying={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        onMouseEnter={() => handleExpand()}
        onClick={() => handleExpand()}
        className={`${
          modal
            ? "h-[184.36px] lg:h-[216px] bg-[#030303]"
            : "w-full bg-[#030303] rounded-t-xl"
        }`}
        ref={videoRef}
        src={videoUrl}
      />
      {showExpand && (
        <div
          onClick={(event) => handlePlayInline(event)}
          className="h-8 w-8 absolute top-2 left-2 h-4 w-4"
        >
          <ExpandIcon />
        </div>
      )}
      <div
        className={`flex items-center bg-primaryDark text-white ${
          modal
            ? "lg:rounded-bl-2xl min-h-[90px] lg:min-h-[140px] gap-4 lg:gap-7 flex-col px-3 p-2 lg:p-4"
            : "xs:flex-col xs:w-full xs:min-h-[140px] sm:min-h-max sm:flex-row rounded-b-xl gap-7 p-4"
        } mt-[-1px]`}
      >
        <PlayButtonComputer
          modal={modal}
          playPauseToggle={playPauseToggle}
          isPlaying={isPlaying}
        />
        <PlayInputComputer
          modal={modal}
          duration={duration}
          setCurrentTime={setCurrentTime}
          handleDragEnd={handleDragEnd}
          handleDragStart={handleDragStart}
          currentTime={currentTime}
          formatTime={formatTime}
        />
        <div
          className={`flex items-center ${
            modal ? "w-full" : "w-full sm:w-max sm:gap-5"
          } justify-between`}
        >
          <PlayButtonMobile
            modal={modal}
            playPauseToggle={playPauseToggle}
            isPlaying={isPlaying}
          />
          <div>
            <select
              className="bg-primaryDark"
              value={speed}
              onChange={(e) =>
                handlePlaybackSpeedChange(parseFloat(e.target.value))
              }
            >
              <option value={0.5}>0.5x</option>
              <option value={1.0}>1.0x</option>
              <option value={1.5}>1.5x</option>
              <option value={2.0}>2.0x</option>
            </select>
          </div>
          <div className="flex items-center gap-5">
            <button onClick={() => handleTimeChange(currentTime - 10)}>
              <RewindTen />
            </button>
            <button onClick={() => handleTimeChange(currentTime + 10)}>
              <ForwordTen />
            </button>
          </div>

          <div className="flex gap-1 items-center">
            <span onClick={toggleMute}>
              {isMuted ? <VolumeMuteIcon /> : <VolumeIcon />}
            </span>
            <input
              type="range"
              className="h-1 w-16"
              min="0"
              max="1"
              step="0.1"
              value={volume}
              onChange={(e) => handleVolumeChange(parseFloat(e.target.value))}
            />
          </div>
          <div className="flex items-center gap-5">
            <button onClick={() => downloadVideo(videoUrl)}>
              <DownloadIcon />
            </button>
          </div>
        </div>
        <PlayInputMobile
          modal={modal}
          duration={duration}
          setCurrentTime={setCurrentTime}
          handleDragEnd={handleDragEnd}
          handleDragStart={handleDragStart}
          currentTime={currentTime}
          formatTime={formatTime}
        />
      </div>
    </div>
  );
};

export default CustomVideoPlayer;
