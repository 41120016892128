import React from "react";
import { Disclosure } from "@headlessui/react";
import { useLocation } from "react-router-dom";
import Header from "../../header/Header";
import Banner from "../../banner/Banner";
import Footer from "../../footer/Footer";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import DataItem from "../../../ReuseableComponents/DataItem";
import DataItemBookmark from "../../../ReuseableComponents/DataItemBookmark";
import arrowUp from "../../../assets/arrowUp.svg";

function AllLatestClasses() {
  const userData = useSelector((state) => state.userdata.userdata);
  const location = useLocation();
  const classdata = location.state ? location.state.data : null;
  const [isLoggedin, setIsLogedIn] = useState(false);
  const [data, setData] = useState([]);
  const [bookmarkLoading, setBookmarkLoading] = useState({ loadingId: null });

  useEffect(() => {
    window.scrollTo(0, 0);
    setData(classdata);
    if (userData) {
      setIsLogedIn(true);
    } else {
      setIsLogedIn(false);
    }
  }, []);

  const renderDropdownIcon = ({ blocks, open }) => {
    for (let i = 0; i < blocks?.length; i++) {
      if (blocks[i].type === "Paragraph" && blocks[i].description !== null) {
        return (
          <Disclosure.Button key={blocks[i].id}>
            <div
              className={`${
                isLoggedin
                  ? "w-[32px] pl-3"
                  : "w-[44px] sm:w-[68px] px-3 sm:px-6 cursor-pointer"
              }`}
            >
              <img
                alt=""
                src={arrowUp}
                className={`${open ? "rotate-180 transform" : ""} h-5 w-5`}
              />
            </div>
          </Disclosure.Button>
        );
      }
    }
  };

  return (
    <>
      <Header />
      <Banner title={"Latest Classes"} />
      {
        <>
          {data ? (
            <>
              <section className="px-2 xs:px-5 pt-6 pb-[38px] md:pt-8 md:pb-[100px]">
                <div className="2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto text-primaryDark">
                  <div className="py-4 sm:py-8 space-y-3 sm:space-y-4">
                    {data?.map((obj) => (
                      <Disclosure key={obj.id}>
                        {({ open }) => (
                          <div>
                            <div className="flex rounded-xl bg-[#E1E9F2] h-[68px] sm:h-[95px]">
                              <DataItem
                                classes={true}
                                open={open}
                                key={obj.id}
                                dataObj={obj}
                                data={data}
                                setData={setData}
                                bookmarkLoading={bookmarkLoading}
                                setBookmarkLoading={setBookmarkLoading}
                                isLoggedin={isLoggedin}
                              />
                              <span className="flex">
                                {renderDropdownIcon({
                                  blocks: obj.blocks,
                                  open,
                                })}
                                {isLoggedin && (
                                  <DataItemBookmark
                                    classes={true}
                                    dataObj={obj}
                                    data={data}
                                    setData={setData}
                                    bookmarkLoading={bookmarkLoading}
                                    setBookmarkLoading={setBookmarkLoading}
                                  />
                                )}
                              </span>
                            </div>
                            <Disclosure.Panel className="text-primaryDark p-2 sm:p-4">
                              <div className="text-lg sm:text-xl font-bold">
                                Description:
                              </div>
                              {obj.blocks?.map((item) => (
                                <div key={item.id}>
                                  {item.type === "Paragraph" && (
                                    <>
                                      <div className="text-xs xs:text-sm sm:text-base font-semibold leading-[normal]">
                                        {" "}
                                        {item.description}
                                      </div>
                                    </>
                                  )}
                                </div>
                              ))}
                            </Disclosure.Panel>
                          </div>
                        )}
                      </Disclosure>
                    ))}
                  </div>
                </div>
              </section>
            </>
          ) : (
            <div className="text-primaryDark text-lg font-bold text-center pt-10 h-[50vh]">
              No Data Found
            </div>
          )}
        </>
      }
      <Footer />
    </>
  );
}

export default AllLatestClasses;
