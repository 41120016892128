export function addAttributes(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const elements = doc.querySelectorAll("video, audio");
  elements.forEach((el) => {
    if (el.tagName === "VIDEO") {
      el.setAttribute("playsinline", "true");
    }
    el.setAttribute("preload", "metadata");
    el.setAttribute("ontimeupdate", `setCookie('${el.src}')`);
    el.setAttribute("onloadedmetadata", `getCookie('${el.src}')`);
  });
  return doc.body.innerHTML;
}
