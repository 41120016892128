import React, { useState, useRef, useEffect } from "react";
import { setCookie, getCookie } from "../ReusableFunctions/cookieHandlers";
import {
  ForwordTen,
  RewindTen,
  VolumeIcon,
  VolumeMuteIcon,
  DownloadIcon,
} from "../assets/icon-file";
import {
  PlayButtonComputer,
  PlayButtonMobile,
  PlayInputComputer,
  PlayInputMobile,
} from "./CustomAudioVideoControls";

const CustomAudioPlayer = ({
  audioUrl,
  onPlay,
  modal = false,
  addEmbedCodeToMediaData,
  mediaData,
  setMediaData,
  checked = false,
  handleStartHereTime,
}) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [speed, setSpeed] = useState(1.0);
  const [volume, setVolume] = useState(1.0);
  const [isMuted, setIsMuted] = useState(false);
  const [dragging, setDragging] = useState(false);
  useEffect(() => {
    const savedTime = !modal ? getCookie(audioUrl) : "";
    if (savedTime) {
      setCurrentTime(savedTime);
      audioRef.current.currentTime = savedTime;
    }
  }, [audioUrl]);

  useEffect(() => {
    const audioElement = audioRef.current;

    const handleLoadedMetadata = () => {
      setDuration(audioElement.duration);
    };

    // const handleTimeUpdate = () => {
    //     setCurrentTime(audioElement.currentTime);
    // };
    const handleTimeUpdate = () => {
      if (!dragging) {
        setCurrentTime(audioElement.currentTime);
      }
      if (modal) {
        handleStartHereTime(audioElement.currentTime);
      }
    };
    const handleEnded = () => {
      // Track completed, pause and reset to start
      audioElement.pause();
      audioElement.currentTime = 0;
      setIsPlaying(false);
    };

    audioElement.addEventListener("loadedmetadata", handleLoadedMetadata);
    audioElement.addEventListener("timeupdate", handleTimeUpdate);
    audioElement.addEventListener("ended", handleEnded);

    return () => {
      audioElement.removeEventListener("loadedmetadata", handleLoadedMetadata);
      audioElement.removeEventListener("timeupdate", handleTimeUpdate);
      audioElement.removeEventListener("ended", handleEnded);
    };
  }, [dragging, checked]);

  const playPauseToggle = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
      setIsPlaying(true);
      if (onPlay) {
        onPlay(); // Call the onPlay function if provided
      }
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handlePlaybackSpeedChange = (newSpeed) => {
    setSpeed(newSpeed);
    audioRef.current.playbackRate = newSpeed;
  };

  const handleVolumeChange = (newVolume) => {
    setVolume(newVolume);
    audioRef.current.volume = newVolume;
  };

  // const handleTimeChange = (newTime) => {
  //     audioRef.current.currentTime = newTime;
  // };
  const handleTimeChange = (newTime) => {
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
    if (modal) {
      handleStartHereTime(newTime);
      if (checked) {
        const mediaDataWithEmbedCode = addEmbedCodeToMediaData({
          mediaData: mediaData,
          startHere: newTime,
        });
        setMediaData(mediaDataWithEmbedCode);
      }
    }
  };

  const handleDragStart = () => {
    setDragging(true);
  };

  const handleDragEnd = (e) => {
    setDragging(false);
    handleTimeChange(parseFloat(e.target.value));
  };

  const toggleMute = () => {
    const newIsMuted = !isMuted;
    setIsMuted(newIsMuted);
    audioRef.current.muted = newIsMuted;
  };
  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    return `${
      hours
        ? `${String(hours)}:${String(minutes).padStart(2, "0")}`
        : `${String(minutes)}`
    }:${String(seconds).padStart(2, "0")}`;
  };

  const downloadAudio = (audioURL) => {
    // Create a link element
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = audioURL;
    //a.download = 'downloaded_audio.mp3'; // You can specify the filename here

    // Append the link to the body
    document.body.appendChild(a);

    // Trigger the download by simulating a click on the link
    a.click();

    // Clean up by removing the link and revoking the blob URL
    window.URL.revokeObjectURL(audioURL);
    document.body.removeChild(a);
  };

  return (
    <div className={`${modal ? "w-[328px] lg:w-[384px]" : ""}`}>
      <audio
        className="hidden"
        ref={audioRef}
        src={audioUrl}
        preload="metadata"
        onTimeUpdate={() => {
          if (!modal) {
            setCookie(audioUrl, currentTime);
          }
        }}
      />
      <div
        className={`flex items-center bg-primaryDark text-white ${
          modal
            ? "lg:rounded-bl-2xl min-h-[274.36px] pt-[192.36px] lg:min-h-[356px] lg:pt-[232px] px-3 py-2 lg:p-4 flex-col gap-4 lg:gap-7"
            : "xs:flex-col xs:w-full xs:min-h-[140px] sm:min-h-max sm:flex-row p-4 rounded-xl gap-7 p-4"
        } mt-[-1px]`}
      >
        <PlayButtonComputer
          modal={modal}
          playPauseToggle={playPauseToggle}
          isPlaying={isPlaying}
        />
        <PlayInputComputer
          modal={modal}
          duration={duration}
          setCurrentTime={setCurrentTime}
          handleDragEnd={handleDragEnd}
          handleDragStart={handleDragStart}
          currentTime={currentTime}
          formatTime={formatTime}
        />
        <div
          className={`flex items-center ${
            modal ? "w-full" : " w-full sm:w-max sm:gap-5"
          } justify-between`}
        >
          <PlayButtonMobile
            modal={modal}
            playPauseToggle={playPauseToggle}
            isPlaying={isPlaying}
          />
          <div>
            <select
              className="bg-primaryDark"
              value={speed}
              onChange={(e) =>
                handlePlaybackSpeedChange(parseFloat(e.target.value))
              }
            >
              <option value={0.5}>0.5x</option>
              <option value={1.0}>1.0x</option>
              <option value={1.5}>1.5x</option>
              <option value={2.0}>2.0x</option>
            </select>
          </div>
          <div className="flex items-center gap-5">
            <button onClick={() => handleTimeChange(currentTime - 10)}>
              <RewindTen />
            </button>
            <button onClick={() => handleTimeChange(currentTime + 10)}>
              <ForwordTen />
            </button>
          </div>

          <div className="flex gap-1 items-center">
            <span onClick={toggleMute}>
              {isMuted ? <VolumeMuteIcon /> : <VolumeIcon />}
            </span>
            <input
              type="range"
              className="h-1 w-16"
              min="0"
              max="1"
              step="0.1"
              value={volume}
              onChange={(e) => handleVolumeChange(parseFloat(e.target.value))}
            />
          </div>

          <div className="flex items-center gap-5">
            <button onClick={() => downloadAudio(audioUrl)}>
              <DownloadIcon />
            </button>
          </div>
        </div>
        <PlayInputMobile
          modal={modal}
          duration={duration}
          setCurrentTime={setCurrentTime}
          handleDragEnd={handleDragEnd}
          handleDragStart={handleDragStart}
          currentTime={currentTime}
          formatTime={formatTime}
        />
      </div>
    </div>
  );
};

export default CustomAudioPlayer;
