import Cookies from "js-cookie";

export const setCookie = (cookieName = null, currentTime = null) => {
  return cookieName
    ? // media using custom players
      Cookies.set(cookieName, currentTime)
    : `
        function setCookie(src) {
          // When there are hebrew letters in the src value they are encoded when passed as an argument
          const decodedSrc = decodeURIComponent(src);
          const media = document.querySelector(\`[src="\${decodedSrc}"]\`);
          // If user leaves page during function validating media will prevent an error
          if (media) {
            Cookies.set(src, media.currentTime);
          }
        }
      `;
};

export const getCookie = (cookieName = null) => {
  return cookieName
    ? // media using custom players
      Cookies.get(cookieName)
    : `async function getCookie(srcValue) {
        // When there are hebrew letters in the src value they are encoded when passed as an argument
        const decodedSrc = decodeURIComponent(srcValue);
        const cookie = await Cookies.get(srcValue);
        const media = document.querySelector(\`[src="\${decodedSrc}"]\`);
        // If user leaves page during function validating media will prevent an error
        if (cookie && media) {
          media.currentTime = cookie;
        }
      }`;
};
