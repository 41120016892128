import React, { useState, useEffect, useRef } from "react";
import { setCookie, getCookie } from "../../ReusableFunctions/cookieHandlers";
import Header from "../../components/header/Header";
import Banner from "../../components/banner/Banner";
import { AddRecentlyPlayed, GetDataFromURL } from "../../service/service";
import { ColorRing } from "react-loader-spinner";
import Footer from "../../components/footer/Footer";
import NotFound from "../notfoundPage/NotFound";
import UserCategory from "../userCategoyPages/UserCategory";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";
import { Helmet } from "react-helmet";
import CustomAudioPlayer from "../../ReuseableComponents/CustomPlayer";
import CustomVideoPlayer from "../../ReuseableComponents/CustomVideoPlayer";
import PDFViewer from "../../ReuseableComponents/PDFViewer";
import { addAttributes } from "../../ReusableFunctions/handleHtmlAttributes";
import { Link } from "react-router-dom";
import EmbedClass from "./EmbedClass";
import { DocumentIcon } from "../../assets/icon-file";
import {
  LoadingOverlay,
  LoadingDocFallback,
} from "../../ReuseableComponents/LoadingDocument";

function SearchClasses({ uniqueName }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [type, setType] = useState("");
  // const { uniqueName } = useParams();
  const [title, setTitle] = useState(
    "Inside Chassidus | Audio Classes | Chassidus for Everyone"
  );
  const [viewDocuments, setViewDocuments] = useState({});
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [iframeStyle, setIframeStyle] = useState({});
  const [widths, setWidths] = useState({
    screenWidth: window.innerWidth,
    fallbackHeight: window.innerWidth > 550 ? "44px" : "72px",
  });
  const [renderIframeFallback, setRenderIframeFallback] = useState({});
  const iframeRefs = useRef({});
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const { screenWidth, fallbackHeight } = widths;

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);

    // Optionally, you can also add a listener for navigation changes
    const handleScrollToTop = () => {
      window.scrollTo(0, 0);
    };

    // Attach the listener to the "beforeunload" event
    window.addEventListener("beforeunload", handleScrollToTop);
    // Clean up the listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleScrollToTop);
    };
  }, [uniqueName]);
  useEffect(() => {
    if (uniqueName !== "") {
      const data = {
        node: uniqueName,
      };
      setLoading(true);
      GetDataFromURL(data).then((result) => {
        setLoading(false);
        if (result.status) {
          setType(result.type);
          setData(result.data);
          if (result.data) {
            setTitle(
              `${result.data?.title ?? result.data?.name} | Inside Chassidus`
            );
          }
        }
      });
    }
  }, [uniqueName]);

  useEffect(() => {
    if (type === "class") {
      // Update these widths on load
      const updateWidths = () => {
        setWidths({
          screenWidth: window.innerWidth,
          fallbackHeight: window.innerWidth > 550 ? "44px" : "72px",
        });
      };
      updateWidths();
      window.addEventListener("resize", updateWidths);
      return () => window.removeEventListener("resize", updateWidths);
    }
  }, [type]);

  useEffect(() => {
    // This will only run if type === classes since only classes will have data.block
    for (let i = 0; i < data?.block?.length; i++) {
      const type = data?.block[i].type;
      const id = data?.block[i].id;
      if (type === "Documents") {
        setIframeStyle((prev) => ({
          ...prev,
          [id]: "500px",
          pdfClasses: "",
          docxClasses: "",
        }));
        setRenderIframeFallback((prev) => ({
          ...prev,
          [id]: false,
        }));
        if (screenWidth < 640) {
          setViewDocuments((prev) => ({ ...prev, [id]: false }));
        }
      }
    }
    // To determine the height of the Iframe element, we check whether the default pdf rendered or the fallback. Smaller screens and Safari use pdf viewer for pdfs and for now the fallback for other docs.
    if (screenWidth > 639) {
      handleRenderingDoc();
    }
  }, [data]);

  const handleRenderingDoc = () => {
    setLoadingDocument(true);
    const timeout = setTimeout(() => {
      for (let i = 0; i < data?.block?.length; i++) {
        const currentData = data?.block[i];
        if (currentData.type === "Documents") {
          if (
            (currentData.url.split(".").pop() === "pdf" && !isSafari) ||
            currentData.url.split(".").pop() === "docx"
          ) {
            handleIsDocRendered({
              id: currentData.id,
              docType: currentData.url.split(".").pop(),
            });
          }
        }
      }
      setLoadingDocument(false);
    }, 1000);
    return () => clearTimeout(timeout);
  };

  const handleIsDocRendered = ({ id, docType }) => {
    // Null contentDocument indicates the Doc rendered
    const isDocRendered = !iframeRefs.current[id]?.contentDocument;
    if (
      !isDocRendered ||
      (!isDocRendered &&
        !iframeRefs.current[id]?.contentDocument?.body?.innerHTML)
    ) {
      if (docType === "pdf") {
        setIframeStyle((prev) => ({
          ...prev,
          pdfClasses: "hidden",
        }));
      }
      if (docType === "docx") {
        setIframeStyle((prev) => ({
          ...prev,
          docxClasses: "hidden",
        }));
      }
      setRenderIframeFallback((prev) => ({
        ...prev,
        [id]: true,
      }));
    } else {
      if (docType === "pdf") {
        setIframeStyle((prev) => ({
          ...prev,
          pdfClasses: "border-b-[2px] border-[#333639]",
        }));
      }
      if (docType === "docx") {
        setIframeStyle((prev) => ({
          ...prev,
          docxClasses: "border-y-[3px] border-[#313131]",
        }));
      }
    }
  };

  const handleViewDocuments = ({ id, docType }) => {
    setViewDocuments((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
    // Currently the Iframe element renders only for screens > 639. So currently this code is esentially disabled since larger screens don't run this function. If we solve the issue with the word docs not conssistently displaying (though the logs show they rendered), we can enable this code for its intended purpose - to check whether the doc rendered and set the style accordingly or show the fallback.
    if (docType === "docx" && screenWidth > 639) {
      setLoadingDocument(true);
      setTimeout(() => {
        // The condition guarantees the function won't run when closing the Doc render.
        if (iframeStyle.docxClasses === "hidden") {
          handleIsDocRendered({ id, docType });
        }
        setLoadingDocument(false);
      }, 1000);
    }
    if (viewDocuments[id]) {
      setRenderIframeFallback((prev) => ({
        ...prev,
        [id]: false,
      }));
      // Reset the Iframe style for docx when closing the document. Currently, no reset would be needed as explained in the long comment above.
      setIframeStyle((prev) => ({
        ...prev,
        docxClasses: docType === "docx" ? "" : iframeStyle.docxClasses,
      }));
    }
  };

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };
  const downloadFile = (fileUrl) => {
    const a = document.createElement("a");
    a.href = fileUrl;
    a.target = "_blank"; // Opens the link in a new tab
    a.click();
  };
  const shareUrl = window.location.href;

  const [lastPlayedMedia, setLastPlayedMedia] = useState({});
  const sendRecentPlayData = (classId, blockId, type) => {
    // Make an API call to add to recent play with class ID, block ID, and type
    const payload = {
      id: classId,
      block_id: blockId,
      type: type,
    };

    // Simulating the API call - Replace this with your actual API call logic
    // console.log('Adding to recent play:', payload);
    AddRecentlyPlayed(payload)
      .then()
      .catch((error) => {
        console.log(error);
      });

    setLastPlayedMedia(payload);
  };

  const handleMediaPlayback = (blockId, blockType) => {
    const classId = data?.id; // Assuming the class ID is available in the data

    if (classId && blockId && blockType) {
      if (
        !(
          lastPlayedMedia.id === classId &&
          lastPlayedMedia.block_id === blockId &&
          lastPlayedMedia.type === blockType
        )
      ) {
        // If current media is different from the last played, trigger API call
        sendRecentPlayData(classId, blockId, blockType);
      }
    }
  };

  return (
    <>
      <Header />
      <Banner
        title={data?.title ?? data?.name}
        description={data?.description}
      />
      <Helmet>
        <title>{title}</title>
        {/* <meta name="description" content='classes' /> */}
      </Helmet>
      {loading ? (
        <div className="flex justify-center min-h-[50vh]">
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#D2D6DC", "#D2D6DC", "#D2D6DC", "#D2D6DC", "#D2D6DC"]}
          />
        </div>
      ) : (
        <div className="min-h-[50vh]">
          {type === "class" && (
            <>
              {data ? (
                <section className="px-2 xs:px-5 pt-6 pb-[38px] md:pt-8 md:pb-[100px]">
                  <div className="2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto text-primaryDark">
                    <div className="text-primaryDark text-sm font-semibold opacity-50 mb-3">
                      {data?.created_at &&
                        `Posted : ${formatDate(
                          data?.is_publish_date ?? data?.created_at
                        )}`}
                    </div>
                    {data?.block?.map((obj) => (
                      <div key={obj.id} className="mb-10">
                        {obj.type === "Paragraph" && (
                          <div className="text-primaryDark text-lg font-semibold">
                            {obj.description}
                          </div>
                        )}
                        {obj.type === "Video" && (
                          <div>
                            <CustomVideoPlayer
                              videoUrl={obj.url}
                              onPlay={() =>
                                handleMediaPlayback(obj.id, "Video")
                              }
                            />
                          </div>
                        )}
                        {obj.type === "Audio" && (
                          <div className="bg-[#f1f3f4] rounded-lg">
                            <CustomAudioPlayer
                              audioUrl={obj.url}
                              onPlay={() =>
                                handleMediaPlayback(obj.id, "Audio")
                              }
                            />
                          </div>
                        )}
                        {obj.type === "Documents" && (
                          <>
                            {screenWidth < 640 && (
                              <div>
                                <button
                                  onClick={() =>
                                    handleViewDocuments({
                                      id: obj.id,
                                      docType: obj.url.split(".").pop(),
                                    })
                                  }
                                  type="button"
                                  className={`mx-auto w-[75%] text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-md px-5 py-1.5 flex gap-2 items-center justify-center ${
                                    viewDocuments[obj.id] ? "mb-3" : ""
                                  }`}
                                >
                                  <DocumentIcon classes="w-6 h-6" />
                                  <span className="w-32">
                                    {viewDocuments[obj.id]
                                      ? "Hide Document"
                                      : "View Document"}
                                  </span>
                                </button>
                              </div>
                            )}
                            {
                              // Only for PDFs using pdf viewer...
                              // Any browser when screen is < 640
                              // Any size screen Safari
                              obj.url.split(".").pop() === "pdf" &&
                                ((screenWidth < 640 &&
                                  viewDocuments[obj.id] === true) ||
                                  (isSafari && screenWidth > 639)) && (
                                  <PDFViewer
                                    id={obj.id}
                                    pdfUrl={obj.url}
                                    downloadFile={downloadFile}
                                  />
                                )
                            }
                            {
                              // For any documents using the default PDF...
                              // Non PDFs in all browsers
                              // PDFs for browsers other than Safari when screen is > 639
                              ((isSafari &&
                                obj.url.split(".").pop() !== "pdf" &&
                                (screenWidth > 639 ||
                                  (screenWidth < 640 &&
                                    viewDocuments[obj.id] === true))) ||
                                (!isSafari &&
                                  ((screenWidth < 640 &&
                                    obj.url.split(".").pop() !== "pdf" &&
                                    viewDocuments[obj.id] === true) ||
                                    screenWidth > 639))) && (
                                <div id={obj.id} className="relative">
                                  {loadingDocument && (
                                    <LoadingOverlay
                                      height="[500px]"
                                      text="Loading Document..."
                                      textSize="text-sm"
                                      classes="absolute top-0"
                                      textClasses={`${
                                        fallbackHeight === "72px" ? "my-5" : ""
                                      }`}
                                    />
                                  )}
                                  {screenWidth > 639 && (
                                    <iframe
                                      title="document"
                                      className={`${
                                        obj.url.split(".").pop() === "pdf"
                                          ? iframeStyle.pdfClasses
                                          : iframeStyle.docxClasses
                                      }`}
                                      ref={(el) =>
                                        (iframeRefs.current[obj.id] = el)
                                      }
                                      src={
                                        obj.url.split(".").pop() === "docx"
                                          ? `https://docs.google.com/gview?url=${obj.url}&embedded=true`
                                          : obj.url
                                      }
                                      type={
                                        obj.url.split(".").pop() === "pdf"
                                          ? "application/pdf"
                                          : obj.url.split(".").pop() === "docx"
                                          ? "text/html"
                                          : ""
                                      }
                                      width="100%"
                                      height={iframeStyle[obj.id]}
                                    />
                                  )}
                                  {(renderIframeFallback[obj.id] ||
                                    screenWidth < 640) && (
                                    <LoadingDocFallback
                                      id={obj.id}
                                      url={obj.url}
                                      downloadFile={downloadFile}
                                    />
                                  )}
                                </div>
                              )
                            }
                          </>
                        )}
                        {obj.type === "Images" && (
                          <>
                            <div className="flex justify-center rounded-lg">
                              <img src={obj.url} alt="class" />
                            </div>
                          </>
                        )}
                        {obj.type === "Custom HTML" && (
                          <>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: addAttributes(obj.html),
                              }}
                            />
                          </>
                        )}
                        <Helmet>
                          <script src="https://cdn.jsdelivr.net/npm/js-cookie@2/src/js.cookie.min.js"></script>
                          <script type="text/javascript">
                            {setCookie() + getCookie()}
                          </script>
                        </Helmet>
                      </div>
                    ))}

                    {data?.is_page !== 1 && (
                      <>
                        {data?.tags?.length > 0 && (
                          <div className="flex flex-wrap gap-3 mt-6 mb-4">
                            {data?.tags?.map((tag) => (
                              <Link
                                key={tag.id}
                                to={`tag/${tag?.slug}`}
                                className="py-2 px-6 bg-primaryDark rounded-md text-base text-whiteGrey"
                              >
                                {tag.name}
                              </Link>
                            ))}
                          </div>
                        )}

                        <div className="font-bold text-xl">
                          Share this class!
                        </div>
                        <div className="flex items-center gap-x-[27px] mt-3">
                          <WhatsappShareButton
                            url={shareUrl}
                            separator=":: "
                            className="Demo__some-network__share-button"
                          >
                            <WhatsappIcon size={32} round />
                          </WhatsappShareButton>
                          <FacebookShareButton
                            url={shareUrl}
                            className="Demo__some-network__share-button"
                          >
                            <FacebookIcon size={32} round />
                          </FacebookShareButton>

                          <TwitterShareButton
                            url={shareUrl}
                            className="Demo__some-network__share-button"
                          >
                            <XIcon size={32} round />
                          </TwitterShareButton>
                          <EmbedClass
                            classData={data}
                            handleMediaPlayback={handleMediaPlayback}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </section>
              ) : (
                <NotFound />
              )}
            </>
          )}
          {type === "category" && <UserCategory data={data} />}
          {type === null && <NotFound />}
        </div>
      )}
      <Footer />
    </>
  );
}

export default SearchClasses;
